<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>资讯动态</h3>
            <p>每一个新动态都是我们向前迈进的新脚步，每一次的发布是行业的<br />前进目标，每一条的新闻都是信息的挖掘！</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//新闻动态-->
    <div class="w news">
        <ul>
            <li v-for="(item,index) in items" :key="index" @click="detail(item.id)" :link="item.link"><div class="img"><img :src="item.thumb" /></div><h3>{{ item.title }}</h3><p>{{ item.description }}</p><p>{{ item.created }}</p></li>
        </ul>
        <div class="clearfix"></div>
    </div>
    <pagination :currentPage="pageNo" :display= "perPage" :total="records" @change="loadoption"></pagination>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import $ from 'jquery';
import pagination from './layout/paginator'
export default {
    components:{
        pagination
    },
    data: function(){
        return {
            page:1,	//默认第一页
            perPage:16,//每页多少条
            pageNo:1,//当前页
            records: 0,//总数
            items: [],
            idx: 0
        };
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        },
        loadoption(idx){
            var __this = this;
            __this.pageNo = idx;
            $.post('https://crm.eqixing.cn/api/news/list',{page: __this.pageNo,psize: __this.perPage},ret=>{
                if(ret.code == 0){
                    //在此更新数据
                    __this.records = ret.total;
                    __this.items = ret.items;
                }
            });
        },
        detail: function(idx){
            this.$router.push({name: 'news_detail',query: {id: idx}});
        }
    },
    mounted: function(){
        var __this = this;
        $.post('https://crm.eqixing.cn/api/news/list',{page: 1,psize: 18},function(ret){
            if(ret.code == 0)
            {
                __this.records = ret.total;
                __this.items = ret.items;
            }
        });
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.news ul,.news ul li,.news ul li h3,.news ul li p{ margin: 0px; padding: 0px; list-style: none;}
.news ul{ margin: 50px 0px;}
.news ul li{ width: 23%; float: left; margin-right: 2%; margin-bottom: 30px; cursor: pointer; height:350px;}
.news ul li .img{ background-color: #eeeeee; height: 250px; border-radius: 5px;}
.news ul li .img img{ width: 100%; max-height: 250px;}
.news ul li h3{ line-height: 40px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;}
.news ul li>p{ line-height: 24px; 
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;}
</style>
