<template>
  <div class="pagination">
      <ul>
        <li class="first btn" :class="{'disabled': current === 1}" @click="setCurrent(current - 1)">
          <a href="javascript:;"><em>上一页</em></a>
        </li>

        <!-- 页数列表 -->
        <li v-for="(item, index) in grouplist" :key="index">
          <a href="javascript:;" @click="setCurrent(item.val)" :class="{'active': current == item.val}">{{item.text}}</a>
        </li>

        <!-- next -->
        <li class="last btn" :class="{'disabled': current === page}" @click="setCurrent(current + 1)">
          <a href="javascript:;"><em>下一页</em></a>
        </li>
      </ul>
  </div>
</template>

<script>
  export default {
    name: 'pagination',
    props: {
      // 当前页码
      currentPage: {
        type: Number,
        default: 1
      },
      // 每页显示条数
      display: {
        type: Number,
        default: 10
      },
      // 总页数
      total: {
        type: Number,
        default: 0
      },
      // 分页条数
      pagegroup: {
        type: Number,
        default: 5,
        coerce: function (v) {
          v = v > 0 ? v : 5;
          return v % 2 === 1 ? v : v + 1;
        }
      }
    },
    data() {
      return {
        pages: null
      }
    },
    computed: {
      /**
       * 返回当前页码
       */
      current() {
        return this.currentPage
      },
      /**
       * 显示总页数
       */
      page() {
        return Math.ceil(this.total / this.display)
      },
      /**
       * 获取分页页码
       */
      grouplist() {
        var len = this.page,
          temp = [],
          list = [],
          count = Math.floor(this.pagegroup / 2),
          center = this.current;
        if (len <= this.pagegroup) {
          while (len--) {
            temp.push({
              text: this.page - len,
              val: this.page - len
            });
          }
          return temp;
        }
        while (len--) {
          temp.push(this.page - len);
        }
        var idx = temp.indexOf(center);
        (idx < count) && (center = center + count - idx);
        (this.current > this.page - count) && (center = this.page - count);
        temp = temp.splice(center - count - 1, this.pagegroup);
        do {
          var t = temp.shift();
          list.push({
            text: t,
            val: t
          });
        } while (temp.length);
        if (this.page > this.pagegroup) {
          (this.current > count + 1) && list.unshift({
            text: '...',
            val: list[0].val - 1
          });
          (this.current < this.page - count) && list.push({
            text: '...',
            val: list[list.length - 1].val + 1
          });
        }
        return list;
      }
    },
    methods: {
      /**
       * 设置当前页
       * @param {String} num  // 当前页数
       */
      setCurrent(num) {
        let idx = parseFloat(num)
        // 如果是点击当前页, 当前页面大于0, 小于总页数
        if (this.current !== idx && idx > 0 && idx < this.page + 1) {
          this.$emit("change", idx)
          this.pages = null
        }
      }
    },
  }
</script>
<style>
.pagination { text-align: center; padding: 40px 0px;}
.pagination ul,.pagination ul li{ list-style: none; margin: 0px; padding: 0px;}
.pagination ul li a:link,.pagination ul li a:visited,.pagination ul li a:hover{ background-color: #eeeeee; padding: 10px 20px; text-decoration: none; color: #000000;}
.pagination ul li a:hover,.pagination ul li a.active{ background-color: #004172; color: #ffffff;}
.pagination ul li{display: inline-block; margin-right: 10px;}
.pagination ul li a em{ font-style: normal;}
.pagination ul li a.active{ background-color: #004172; color: #ffffff;}
.pagination ul li.disabled a{ background-color: #eeeeee; color: #666666;}
</style>